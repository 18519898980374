body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-brand img {
  height: auto;
}

.navbar-nav {
  gap: 30px;
}

.about {
  height: 650px;
  background-color: #5d4037;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;


}

.bg {
  background: url("/public/images/wheat-field.jpg");
  background-size: cover;
  align-items: center;
  top: 0;
  left: 0;

}

.box {
  height: 500px;
  width: 300px;
  overflow: hidden;
  background: gray;
  margin-top: 2%;
  margin-bottom: 4%;
  margin-left: 2%;
  margin-right: 2%;
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  text-align: center;
  border-radius: 25px;
}


.box:hover>.hid-box {
  top: 0;
}


.hid-box {
  top: 100%;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: center;
  display: flex;
  text-align: center;
  align-items: center;
  transition: all .3s ease-out;
  background: darkgray;
  border-radius: 25px;

}

.visible-box {
  position: absolute;
  transition: all .3s ease-out;
  border-radius: 25px;
}

.box:hover>.visible-box {
  top: 0;
  bottom: 0;
}

.two {
  /* height: 450px; */
  background-color: grey;
  display: flex;
  margin-bottom: 4%;
  align-items: center;
  text-align: center;
  justify-content: center;
  flex-direction: column;
  color: white;
  opacity: 0.95;
  letter-spacing: 2px;
  line-height: 28.5px;
  border-radius: 25px;
  margin-top: 3%;
}

.yo {
  bottom: 0;
  z-index: 10;
  translate: 0 100%;
  transition: translate 250ms ease-in-out;
}

.can {
  height: 400px;
  width: 380px;
  opacity: 0.95;
  background-color: grey;
  display: flex;
  justify-content: center;
  color: white;
  letter-spacing: 2px;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
  border-radius: 25px;
  align-items: center;
}

.nac {
  height: 350px;
  width: 260px;
  opacity: 0.95;
  background-color: grey;
  display: flex;
  justify-content: center;
  color: white;
  letter-spacing: 2px;
  flex-direction: column;
  text-align: center;
  margin-bottom: 2%;
  margin-right: 2%;
  margin-left: 2%;
  border-radius: 25px;
  align-items: center;
}



.why {
  font-size: 14px;
  border-radius: 25px;
}

.contact {
  height: 400px;
  background-color: #C4A484;
  opacity: 0.95;
  margin-top: 3.5%;
  color: black;
  border-radius: 25px;
  margin-bottom: 3%;
}

.portfolio-card {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  perspective: 1000px;
  background-color: transparent;
  border-radius: 25px;
  margin-top: 5%
}


.portfolio-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  border-radius: 25px;
}

.subtitle {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.portfolio-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-back {
  background-color: #5d4037;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  color: white;
  font-size: 14px;
}

.btn {
  background-color: #5d4037;
  color: white;

}

.btn:hover {
  background-color: #715850;
  color: white;
}



.flip-card-front {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background-color: #EBE7E6;
  font-size: 18px;
}